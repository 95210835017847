<template>
    <section>
      <div class="w-full rounded-lg shadow-md p-4">
        <div class="w-full border-b-2 border-blue-600 ">
          <p class="text-blue-600 font-medium text-md">
            Datos personales
          </p>
        </div>
        <div class="w-full grid grid-cols-2 gap-4 mt-4 lg:grid-cols-3">
          <div>
            <label class="text-gray-600 text-xs">Número de documento:</label>
            <p class="font-bold mt-1">
              {{persona.type_document_id + ' ' + persona.number_document}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Género:</label>
            <p class="font-bold mt-1">
              {{empleado.Genero}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Estado civil:</label>
            <p class="font-bold mt-1">
              {{empleado.EstadoCivil}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Localización:</label>
            <p class="font-bold mt-1" v-if="localizacion">
              {{localizacion.nomMpio + ', ' + localizacion.nomDpto}}
            </p>
            <p v-else>-</p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Dirección:</label>
            <p class="font-bold mt-1">
              {{persona.direccion}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Fecha de nacimiento:</label>
            <p class="font-bold mt-1">
              {{dayjs(empleado.FechaNacimiento).format('DD/MM/YYYY')}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Hijos:</label>
            <p class="font-bold mt-1">
              {{empleado.NumeroHijos}}
            </p>
          </div>
        </div>
        <div class="w-full mt-4 border-b-2 border-blue-600 ">
          <p class="text-blue-600 font-medium text-md">
            Datos del cargo
          </p>
        </div>
        <div class="w-full grid grid-cols-2 gap-4 mt-4 lg:grid-cols-3">
          <div>
            <label class="text-gray-600 text-xs">Cargo:</label>
            <p class="font-bold mt-1">
              {{cargo.name}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Salario:</label>
            <p class="font-bold mt-1">
              {{contrato.salario}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Jefe inmediato:</label>
            <p class="font-bold mt-1">
              {{empleado.JefeInmediato}}
            </p>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>

  import empleadosStore from '../../../../../stores/empleados.store'
  import { computed } from 'vue'
  import dayjs from 'dayjs'

  export default {
    name: 'ComponentPersonalData',
    setup () {
      // Service
      // References
      const empleado = computed(() => empleadosStore.getters._dataEmpleado)
      const persona = computed(() => empleadosStore.getters._dataPersona)
      const localizacion = computed(() => empleadosStore.getters._localizacion)
      const cargo = computed(() => empleadosStore.getters._cargo)
      const contrato = computed(() => empleadosStore.getters._dataContratos)

      return {
        localizacion,
        dayjs,
        empleado,
        persona,
        cargo,
        contrato
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  ::v-deep(.p-dropdown-trigger) {
    display: none;

  }
  ::v-deep(.p-button.p-component.p-button-icon-only.p-datepicker-trigger) {
    background-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
    border-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
}
  </style>
